import {
  IAccountVerificationRequest,
  IReturnApproveDecision,
  IReturnDecision,
} from '../../interfaces/return-list/return-decision.interface';
import { ITableService } from 'src/app/common/interfaces/table/table-service.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IReturnList } from '../../interfaces/return-list/return-list.interface';

export abstract class AbstractReturnListService extends ITableService {
  abstract tableFilterSingleRecordById(messageId: string): Promise<IReturnList | undefined>;
  abstract reasonDenyList(): Promise<any>;
  abstract returnList(): Promise<any>;
  abstract getById(messageId: string): Promise<any>;
  abstract getPendingDecisionReturnCount(): Promise<number | undefined>;
  abstract approve(decision: IReturnApproveDecision): Promise<IWebMessage | undefined>;
  abstract deny(decision: IReturnDecision): Promise<IWebMessage | undefined>;
  abstract accountVerification(
    detail: IAccountVerificationRequest
  ): Promise<IWebMessage | undefined>;
}
