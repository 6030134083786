import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AbstractGeneralService } from '../abstraction/abstract.general.service';
import { IFilterDates } from '../../interfaces/general/filter-dates.interface';

@Injectable({
  providedIn: 'root',
})
export class MockGeneralService extends AbstractGeneralService {
  private getFilterDateDateJson = 'assets/mocks/general/getFromToDate.json';

  constructor(private http: HttpClient) {
    super();
  }

  getFromToDate(): Promise<IFilterDates | undefined> {
    return lastValueFrom(this.http.get<IFilterDates>(this.getFilterDateDateJson));
  }
}
