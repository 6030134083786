<div class="infoUser flex flex-column" *ngIf="!isSlim || isMobile">
  <hr class="customHr" />
  <span class="information font-bold uppercase underline" align="middle">INFORMATION</span>

  <table class="table-layout-fixed table-vertical-align-text border-round-md w-full">
    <tr>
      <td align="left" class="title pt-0 white-space-nowrap w-3">FI RTN:</td>
      <td class="value pr-1 pt-0"><span [innerHTML]="data?.primaryRTN || ''"></span></td>
    </tr>
    <tr>
      <td align="left" class="title pt-0 white-space-nowrap w-3">FI Name:</td>
      <td class="value pr-1 pt-1">
        <div
          [innerHTML]="truncatedName || ''"
          pTooltip="{{ this.data?.name }}"
          tooltipPosition="top"></div>
      </td>
    </tr>
  </table>
  <br />
  <div
    class="title mt-3 p-1 border-1 border-round-md w-full"
    [ngStyle]="{ 'border-color': 'var(--active-menuitem-text-color)' }">
    <span class="underline">Note</span>: All Date Time shown in this portal are in Eastern Time.
  </div>
</div>
