import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { ITableService } from '../../../common/interfaces/table/table-service.interface';
import { IRFPRequestCancellation } from '../../interfaces/rfp-send/rfp-request-cancellation.interface';
import { IRFPSend } from '../../interfaces/rfp-send/rfp-send.interface';
import { IRFPSentDetails } from '../../interfaces/rfp-send/rfp-sent-details.interface';
import { IRFPSentList } from '../../interfaces/rfp-send/rfp-sent-list.interface';

export abstract class AbstractRFPSendService extends ITableService {
  abstract tableFilterSingleRecordById(messageId: string): Promise<IRFPSentList | undefined>;
  abstract rfpGenerate(rfp: IRFPSend): Promise<IWebMessage | undefined>;
  abstract rfpGenerateZeroDollar(rfp: IRFPSend): Promise<IWebMessage | undefined>;
  abstract rfpStatusList(): Promise<any>;
  abstract rfpCancellationStatusList(): Promise<any>;
  abstract getDetail(messageId: string): Promise<IRFPSentDetails | undefined>;
  abstract rfpRequestCancelReasonCodes(): Promise<any>;
  abstract rfpRequestCancellation(
    rfpCancellationRequest: IRFPRequestCancellation
  ): Promise<IWebMessage | undefined>;
  abstract validateRtnRequestForPaymentReceiveAllowed(
    routingNumber: string
  ): Promise<IWebMessage | undefined>;
}
