import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { AbstractRespondentService } from '../abstraction/abstract.respondent.service';
import { lastValueFrom } from 'rxjs';
import { IValidateParticipant } from '../../interfaces/send-payment/validate-participant.interface';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';

@Injectable({
  providedIn: 'root',
})
export class MockRespondentService extends AbstractRespondentService {
  private validateRtnJson = 'assets/mocks/respondent/validateRtn.json';
  private tableFilterJson = 'assets/mocks/respondent/tableFilter.json';

  constructor(private http: HttpClient) {
    super();
  }

  validateRtn(routingNumber: string): Promise<IValidateParticipant | undefined> {
    return lastValueFrom(this.http.get<IValidateParticipant>(this.validateRtnJson));
  }

  override tableFilter(
    filter: ITableServerFilter
  ): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }
  override tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }
  override tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    throw new Error('Method not implemented.');
  }
}
