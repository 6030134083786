import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthGuardService as AuthGuard } from './layout/service/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptor/jwt.token.interceptor';
import { CookieInterceptor } from './interceptor/cookie.interceptor';
import { UnauthorizedInterceptor } from './interceptor/unauthorized.interceptor';
import { LoadingInterceptor } from './interceptor/loading.interceptor';
import { LoadingOverlayComponent } from './layout/loading-overlay.component';
import { serviceProviders } from './service-providers';

@NgModule({
  declarations: [AppComponent, LoadingOverlayComponent],
  imports: [AppRoutingModule, AppLayoutModule, CommonModule],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    ...serviceProviders,
    AuthGuard,
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
