import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoadingService } from '../layout/service/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('Skip-Loading')) {
      const modifiedReq = req.clone({
        headers: req.headers.delete('Skip-Loading'),
      });
      return next.handle(modifiedReq);
    }

    this.loadingService.begin();
    return next.handle(req).pipe(finalize(() => this.loadingService.end()));
  }
}
