import { ITableService } from 'src/app/common/interfaces/table/table-service.interface';
import { IRFPList } from '../../interfaces/rfp-receive/rfp-list.interface';
import { IRFP } from '../../interfaces/rfp-receive/rfp.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IRFPDecision } from '../../interfaces/rfp-receive/rfp-decision.interface';
import { IRFPCancellationDecision } from '../../interfaces/rfp-receive/rfp-cancellation-decision.interface';

export abstract class AbstractRFPReceiveService extends ITableService {
  abstract tableFilterSingleRecordById(messageId: string): Promise<IRFPList | undefined>;
  abstract getDetail(messageId: string): Promise<IRFP | undefined>;
  abstract rfpStatus(): Promise<any>;
  abstract rfpCancellations(): Promise<any>;
  abstract rfpDecisionList(): Promise<any>;
  abstract rfpDenyDecision(): Promise<any>;
  abstract rfpCancellationDenyDecision(): Promise<any>;

  abstract rfpDecisionAccept(decision: IRFPDecision): Promise<IWebMessage | undefined>;
  abstract rfpDecisionDeny(decision: IRFPDecision): Promise<IWebMessage | undefined>;
  abstract rfpCancellationDeny(
    decision: IRFPCancellationDecision
  ): Promise<IWebMessage | undefined>;
  abstract rfpCancellationAccept(
    decision: IRFPCancellationDecision
  ): Promise<IWebMessage | undefined>;
  abstract rfpDecisionAcceptNoteMessage(decision: IRFPDecision): Promise<IWebMessage | undefined>;
}
