import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AbstractAuditReviewService } from '../abstraction/abstract.audit-review.service';
import { ITableServerResponseFilter } from '../../../common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from '../../../common/interfaces/table/table-server-filter.inteface';

@Injectable({
  providedIn: 'root',
})
export class MockAuditReviewService extends AbstractAuditReviewService {
  private tableFilterJson = 'assets/mocks/configuration/audit-review/tableFilter.json';

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }
  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }
  tableExportExcel(filter: ITableServerFilter): Promise<any> {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) {
    super();
  }
}
