import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { ITableServerResponseFilter } from '../../../common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from '../../../common/interfaces/table/table-server-filter.inteface';
import { AbstractRFPSendZeroDollarService } from '../abstraction/abstract.rfp-send-zero-dollar.service';
import { IRFZeroDollarSentList } from '../../interfaces/rfp-send/rfp-zero-dollar-sent-list.interface';
import { IRFPZeroDollarSentDetails } from '../../interfaces/rfp-send/rfp-zero-dollar-sent-details.interface';

@Injectable({
  providedIn: 'root',
})
export class RFPZeroDollarSendService extends AbstractRFPSendZeroDollarService {
  controller = `/manager/rfp-zero-dollar-send`;
  urlGetZeroDollarRfpList = `${environment.apiUrl}${this.controller}/grid`;
  urlGetSentZeroDollarRfpListExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  urlGetSentZeroDollarRfpListExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetRfpStatusList = `${environment.apiUrl}${this.controller}/zeroDollarRfpStatusList`;
  urlGetDetail = `${environment.apiUrl}${this.controller}/detail`;

  tableFilterSingleRecordById(messageId: string): Promise<IRFZeroDollarSentList | undefined> {
    throw new Error('Method not implemented.');
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetZeroDollarRfpList}`, filter)
      .toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetSentZeroDollarRfpListExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any> {
    return this.http.post(`${this.urlGetSentZeroDollarRfpListExportExcel}`, filter).toPromise();
  }

  rfpStatusList(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetRfpStatusList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  getDetail(messageId: string): Promise<IRFPZeroDollarSentDetails | undefined> {
    return this.http
      .get<IRFPZeroDollarSentDetails>(`${this.urlGetDetail}/${messageId}`)
      .toPromise();
  }

  constructor(private http: HttpClient) {
    super();
  }
}
