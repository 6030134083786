import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IInstitution } from '../../interfaces/institution/institution.interface';
import { lastValueFrom } from 'rxjs';
import { AbstractInstitutionService } from '../abstraction/abstract.institution.service';
import { IInstitutionConfiguration } from '../../interfaces/institution/institution-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class MockInstitutionService extends AbstractInstitutionService {
  private getInformationJson = 'assets/mocks/institution/getInformation.json';
  private getConfigurationJson = 'assets/mocks/institution/getConfiguration.json';

  constructor(private http: HttpClient) {
    super();
  }

  getInformation(): Promise<IInstitution | undefined> {
    return lastValueFrom(this.http.get<IInstitution>(this.getInformationJson));
  }

  getConfiguration(): Promise<IInstitutionConfiguration | undefined> {
    return lastValueFrom(this.http.get<IInstitutionConfiguration>(this.getConfigurationJson));
  }
}
