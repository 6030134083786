import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IReturnInitiate } from '../../interfaces/return-list/return-initiate.interface';
import { IInitiatedReturnDetails } from '../../interfaces/initiated-return/initiated-return-detail.interface';
import { IInitiatedReturnList } from '../../interfaces/initiated-return/initiated-return-list.interface';
import { AbstractReturnInitiateService } from '../abstraction/abstract.return-initiated.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockReturnInitiateService extends AbstractReturnInitiateService {
  private tableFilterJson = 'assets/mocks/returninitiated/tableFilter.json';
  private reasonInitiateReturnListJson =
    'assets/mocks/returninitiated/reasonInitiateReturnList.json';
  private fraudClassifierListJson = 'assets/mocks/returninitiated/fraudClassifierList.json';
  private initiateJson = 'assets/mocks/returninitiated/initiate.json';
  private getDetailApprovedJson = 'assets/mocks/returninitiated/getDetailApproved.json';
  private getDetailDeniedJson = 'assets/mocks/returninitiated/getDetailDenied.json';
  private getDetailOrphanJson = 'assets/mocks/returninitiated/getDetailOrphan.json';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableFilterSingleRecordById(messageId: string): Promise<IInitiatedReturnList | undefined> {
    throw new Error('Method not implemented.');
  }

  async getDetail(messageId: string): Promise<IInitiatedReturnDetails | undefined> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId);

    if (original.returnStatus == 'Return Approved')
      return lastValueFrom(this.http.get<IInitiatedReturnDetails>(this.getDetailApprovedJson));
    if (original.returnStatus == 'Return Denied')
      return lastValueFrom(this.http.get<IInitiatedReturnDetails>(this.getDetailDeniedJson));
    if (original.returnStatus == 'Return Orphan')
      return lastValueFrom(this.http.get<IInitiatedReturnDetails>(this.getDetailOrphanJson));
    return undefined;
  }

  getDetailFromTransaction(messageId: string): Promise<IInitiatedReturnDetails | undefined> {
    throw new Error('Method not implemented.');
  }

  initiate(initiateReturn: IReturnInitiate): Promise<IWebMessage | undefined> {
    return lastValueFrom(this.http.get<IWebMessage>(this.initiateJson));
  }

  reasonInitiateReturnList(messageId: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.reasonInitiateReturnListJson));
  }

  fraudClassifierList(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.fraudClassifierListJson));
  }
}
