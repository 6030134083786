import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { AbstractRFPReceiveService } from '../abstraction/abstract.rfp-receive.service';
import { IRFPList } from '../../interfaces/rfp-receive/rfp-list.interface';
import { lastValueFrom } from 'rxjs';
import { IRFP } from '../../interfaces/rfp-receive/rfp.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IRFPCancellationDecision } from '../../interfaces/rfp-receive/rfp-cancellation-decision.interface';
import { IRFPDecision } from '../../interfaces/rfp-receive/rfp-decision.interface';
import { RfpStatusCodes } from '../../constants/rfp-receive/rfpstatus.constants';
import { RfpCancellationStatusCodes } from '../../constants/rfp-receive/rfpcancellationstatus.constants';

@Injectable({
  providedIn: 'root',
})
export class MockRFPReceiveService extends AbstractRFPReceiveService {
  private tableFilterJson = 'assets/mocks/rfp-receive/tableFilter.json';
  private rfpStatusJson = 'assets/mocks/rfp-receive/rfpStatus.json';
  private rfpCancellationsJson = 'assets/mocks/rfp-receive/rfpCancellations.json';
  private rfpDecisionJson = 'assets/mocks/rfp-receive/rfpDecision.json';
  private rfpDenyDecisionJson = 'assets/mocks/rfp-receive/rfpDenyDecision.json';
  private rfpCancellationDenyDecisionJson =
    'assets/mocks/rfp-receive/rfpCancellationDenyDecision.json';
  private getById_DecisionPendingJson = 'assets/mocks/rfp-receive/getById_DecisionPending.json';
  private getById_DecisionAcceptedJson = 'assets/mocks/rfp-receive/getById_DecisionAccepted.json';
  private getById_DecisionAcceptedPaidJson =
    'assets/mocks/rfp-receive/getById_DecisionAcceptedPaid.json';
  private getById_DecisionPendingCancellationReceivedJson =
    'assets/mocks/rfp-receive/getById_DecisionPendingCancellationReceived.json';
  private getById_DecisionCanceledCancellationAcceptedJson =
    'assets/mocks/rfp-receive/getById_DecisionCanceledCancellationAccepted.json';
  private getById_DecisionAcceptedPaidCancellationDeniedJson =
    'assets/mocks/rfp-receive/getById_DecisionAcceptedPaidCancellationDenied.json';
  private getById_DecisionDeniedJson = 'assets/mocks/rfp-receive/getById_DecisionDenied.json';
  private getById_AcceptedFailedPaymentJson =
    'assets/mocks/rfp-receive/getById_AcceptedFailedPayment.json';
  private getById_ExpiredJson = 'assets/mocks/rfp-receive/getById_Expired.json';

  private rfpDecisionAcceptSuccessJson = 'assets/mocks/rfp-receive/rfpDecisionAccept_Success.json';
  private rfpDecisionAcceptErrorJson = 'assets/mocks/rfp-receive/rfpDecisionAccept_Error.json';
  private rfpDecisionDenySuccessJson = 'assets/mocks/rfp-receive/rfpDecisionDeny_Success.json';
  private rfpDecisionDenyErrorJson = 'assets/mocks/rfp-receive/rfpDecisionDeny_Error.json';
  private rfpDecisionAcceptNoteMessageJson =
    'assets/mocks/rfp-receive/rfpDecisionAcceptNoteMessage.json';

  private rfpCancellationAcceptSuccessJson =
    'assets/mocks/rfp-receive/rfpCancellationAccept_Success.json';
  private rfpCancellationAcceptErrorJson =
    'assets/mocks/rfp-receive/rfpCancellationAccept_Error.json';
  private rfpCancellationDenySuccessJson =
    'assets/mocks/rfp-receive/rfpCancellationDeny_Success.json';
  private rfpCancellationDenyErrorJson = 'assets/mocks/rfp-receive/rfpCancellationDeny_Error.json';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  async tableFilterSingleRecordById(messageId: string): Promise<IRFPList | undefined> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId) as IRFPList;
    return original;
  }

  async getDetail(messageId: string): Promise<IRFP | undefined> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId) as IRFPList;

    if (original.rfpStatus == RfpStatusCodes.DecisionPending && original.cancellationStatus == null)
      return lastValueFrom(this.http.get<IRFP>(this.getById_DecisionPendingJson));
    if (original.rfpStatus == RfpStatusCodes.Denied && original.cancellationStatus == null)
      return lastValueFrom(this.http.get<IRFP>(this.getById_DecisionDeniedJson));
    if (original.rfpStatus == 'Accepted' && original.cancellationStatus == null)
      return lastValueFrom(this.http.get<IRFP>(this.getById_DecisionAcceptedJson));
    if (original.rfpStatus == RfpStatusCodes.AcceptedPaid && original.cancellationStatus == null)
      return lastValueFrom(this.http.get<IRFP>(this.getById_DecisionAcceptedPaidJson));
    if (
      original.rfpStatus == RfpStatusCodes.DecisionPending &&
      original.cancellationStatus == RfpCancellationStatusCodes.Received
    )
      return lastValueFrom(
        this.http.get<IRFP>(this.getById_DecisionPendingCancellationReceivedJson)
      );
    if (
      original.rfpStatus == RfpStatusCodes.Canceled &&
      original.cancellationStatus == RfpCancellationStatusCodes.Accepted
    )
      return lastValueFrom(
        this.http.get<IRFP>(this.getById_DecisionCanceledCancellationAcceptedJson)
      );
    if (
      original.rfpStatus == RfpStatusCodes.AcceptedPaid &&
      original.cancellationStatus == RfpCancellationStatusCodes.Denied
    )
      return lastValueFrom(
        this.http.get<IRFP>(this.getById_DecisionAcceptedPaidCancellationDeniedJson)
      );
    if (original.rfpStatus == RfpStatusCodes.AcceptedFailedPayment)
      return lastValueFrom(this.http.get<IRFP>(this.getById_AcceptedFailedPaymentJson));
    if (original.rfpStatus == RfpStatusCodes.Expired)
      return lastValueFrom(this.http.get<IRFP>(this.getById_ExpiredJson));

    return undefined;
  }

  rfpStatus(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpStatusJson));
  }

  rfpCancellations(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpCancellationsJson));
  }

  rfpDecisionList(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpDecisionJson));
  }

  rfpDenyDecision(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpDenyDecisionJson));
  }

  rfpCancellationDenyDecision(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpCancellationDenyDecisionJson));
  }

  rfpDecisionAccept(decision: IRFPDecision): Promise<IWebMessage | undefined> {
    if (decision.additionalInformation == 'Error') {
      return lastValueFrom(this.http.get<any>(this.rfpDecisionAcceptErrorJson));
    }

    return lastValueFrom(this.http.get<any>(this.rfpDecisionAcceptSuccessJson));
  }

  rfpDecisionDeny(decision: IRFPDecision): Promise<IWebMessage | undefined> {
    if (decision.additionalInformation == 'Error') {
      return lastValueFrom(this.http.get<any>(this.rfpDecisionDenyErrorJson));
    }

    return lastValueFrom(this.http.get<any>(this.rfpDecisionDenySuccessJson));
  }

  rfpCancellationDeny(decision: IRFPCancellationDecision): Promise<IWebMessage | undefined> {
    if (decision.additionalInformation == 'Error') {
      return lastValueFrom(this.http.get<any>(this.rfpCancellationDenyErrorJson));
    }

    return lastValueFrom(this.http.get<any>(this.rfpCancellationDenySuccessJson));
  }

  rfpCancellationAccept(decision: IRFPCancellationDecision): Promise<IWebMessage | undefined> {
    if (decision.additionalInformation == 'Error') {
      return lastValueFrom(this.http.get<any>(this.rfpCancellationAcceptErrorJson));
    }

    return lastValueFrom(this.http.get<any>(this.rfpCancellationAcceptSuccessJson));
  }

  rfpDecisionAcceptNoteMessage(decision: IRFPDecision): Promise<IWebMessage | undefined> {
    return lastValueFrom(this.http.get<any>(this.rfpDecisionAcceptNoteMessageJson));
  }
}
