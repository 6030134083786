import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { lastValueFrom } from 'rxjs';
import { AbstractRFPSendZeroDollarService } from '../abstraction/abstract.rfp-send-zero-dollar.service';
import { ITableServerFilter } from '../../../common/interfaces/table/table-server-filter.inteface';
import { ITableServerResponseFilter } from '../../../common/interfaces/table/table-server-filter-response.interface';
import { IRFZeroDollarSentList } from '../../interfaces/rfp-send/rfp-zero-dollar-sent-list.interface';
import { IRFPZeroDollarSentDetails } from '../../interfaces/rfp-send/rfp-zero-dollar-sent-details.interface';
import { ZeroDollarRfpSentStatusCodes } from '../../constants/rfp-send/zerodollarrfpstatus.constants';

@Injectable({
  providedIn: 'root',
})
export class MockRFPZeroDollarSendService extends AbstractRFPSendZeroDollarService {
  private rfpStatusJson = 'assets/mocks/rfp-send/zeroDollar/rfpStatus.json';
  private tableFilterJson = 'assets/mocks/rfp-send/zeroDollar/tableFilter.json';
  private getById_RFPDenied = 'assets/mocks/rfp-send/zeroDollar/getById_RFPDenied.json';
  private getById_RFPAccepted = 'assets/mocks/rfp-send/zeroDollar/getById_RFPAccepted.json';
  private getById_RFPInitiated = 'assets/mocks/rfp-send/zeroDollar/getById_RFPInitiated.json';
  private getById_RFPExpired = 'assets/mocks/rfp-send/zeroDollar/getById_RFPExpired.json';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableFilterSingleRecordById(messageId: string): Promise<IRFZeroDollarSentList | undefined> {
    throw new Error('Method not implemented.');
  }

  rfpStatusList(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpStatusJson));
  }

  async getDetail(messageId: string): Promise<IRFPZeroDollarSentDetails | undefined> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId) as IRFZeroDollarSentList;

    if (original.rfpZeroDollarSendStatus == ZeroDollarRfpSentStatusCodes.Denied) {
      return lastValueFrom(this.http.get<IRFPZeroDollarSentDetails>(this.getById_RFPDenied));
    }

    if (original.rfpZeroDollarSendStatus == ZeroDollarRfpSentStatusCodes.Initiated)
      return lastValueFrom(this.http.get<IRFPZeroDollarSentDetails>(this.getById_RFPInitiated));

    if (original.rfpZeroDollarSendStatus == ZeroDollarRfpSentStatusCodes.Accepted)
      return lastValueFrom(this.http.get<IRFPZeroDollarSentDetails>(this.getById_RFPAccepted));

    if (original.rfpZeroDollarSendStatus == ZeroDollarRfpSentStatusCodes.Expired)
      return lastValueFrom(this.http.get<IRFPZeroDollarSentDetails>(this.getById_RFPExpired));

    return undefined;
  }
}
