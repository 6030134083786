import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/layout/service/util.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { LoadingService } from './layout/service/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  apiResult: string | undefined;
  fullName: string | undefined;
  isSignedIn = false;
  showLoading: boolean = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    private util: UtilService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    public loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    if (environment.useMock) {
      this.isSignedIn = true;
      const userType = sessionStorage.getItem('mockUserType');
      if (userType === null) {
        sessionStorage.setItem('mockUserType', 'Respondent');
      }
      this.router.navigate(['fednow/account-activity']);
      return;
    }

    // JWT is returned in a browser cookie
    var jwt = this.util.getCookieByName(environment.jwtCookieName);
    if (jwt) {
      // Set flag
      this.isSignedIn = true;

      // Decode the token, assign full name property
      const decodedJwt = this.jwtHelper.decodeToken(jwt);
      this.fullName = `${decodedJwt.given_name} ${decodedJwt.family_name}`;
      this.router.navigate(['fednow/account-activity']);
    } else {
      // Set flag
      this.isSignedIn = false;
    }

    this.loadingService.loading$.subscribe(x => {
      this.showLoading = x;
      this.cdr.detectChanges();
    });
  }
}
