import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { UtilService } from 'src/app/layout/service/util.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { SessionService } from '../fednow/service/http/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.css'],
})
export class AppTopbarComponent implements OnInit {
  fullName: string | undefined;
  useMock: boolean = false;
  items: any;
  currentUserType: string = '';
  @ViewChild('menuButton') menuButton!: ElementRef;

  @ViewChild('mobileMenuButton') mobileMenuButton!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private util: UtilService,
    private jwtHelper: JwtHelperService,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.useMock = environment.useMock;
    if (this.useMock) {
      const userType = sessionStorage.getItem('mockUserType');
      this.currentUserType = userType ?? '';

      this.items = [
        {
          label: 'Correspondent',
          icon: 'pi pi-refresh',
          command: () => {
            this.switchMockTo('Correspondent');
          },
        },
        {
          label: 'Respondent',
          icon: 'pi pi-refresh',
          command: () => {
            this.switchMockTo('Respondent');
          },
        },
      ];
    }
  }

  activeItem!: number;

  get mobileTopbarActive(): boolean {
    return this.layoutService.state.topbarMenuActive;
  }

  async ngOnInit() {
    this.getProfile();
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onMobileTopbarMenuButtonClick() {
    this.layoutService.onTopbarMenuToggle();
  }

  get isSlim() {
    return this.layoutService.isSlim();
  }

  getProfile() {
    // JWT is returned in a browser cookie
    var jwt = this.util.getCookieByName(environment.jwtCookieName);
    if (jwt) {
      // Decode the token, assign full name property
      const decodedJwt = this.jwtHelper.decodeToken(jwt);
      this.fullName = `${decodedJwt.unique_name}`;
    }
  }

  async logout() {
    await this.sessionService.logout();
    this.router.navigate(['logout']);
  }

  switchMockTo(type: string) {
    sessionStorage.setItem('mockUserType', type);
    window.location.reload();
  }
}
