import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ISettlementTransaction } from '../../interfaces/settlement/settlement-transaction.interface';
import { AbstractSettlementService } from '../abstraction/abstract.settlement.service';

@Injectable({
  providedIn: 'root',
})
export class SettlementService extends AbstractSettlementService {
  controller = `/manager/fednowsettlementtransaction`;
  urlGetAccountActivityRespondentTransaction = `${environment.apiUrl}${this.controller}/getrespondenttransactions`;

  constructor(private http: HttpClient) {
    super();
  }

  getTransactionsByRespondentAndCycle(
    respondentRtn: string,
    cycleDate: string
  ): Promise<ISettlementTransaction[] | undefined> {
    return this.http
      .get<
        ISettlementTransaction[]
      >(`${this.urlGetAccountActivityRespondentTransaction}/${respondentRtn}/${cycleDate}`)
      .toPromise();
  }
}
