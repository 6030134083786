import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { AbstractConfigurationReviewStatusService } from '../abstraction/abstract.configuration-review-status.service';
import { environment } from 'src/environments/environment';
import { IDualControlDetail } from '../../interfaces/configuration/dual-control-detail.interface';
import { IDualControlCount } from '../../interfaces/configuration/dual-control-count.interface';
@Injectable({
  providedIn: 'root',
})
export class ConfigurationReviewStatusService extends AbstractConfigurationReviewStatusService {
  controller = `/manager/dualcontrolreviewstatus`;
  urlGetGridList = `${environment.apiUrl}${this.controller}/grid`;
  urlGetGridExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  urlGetGridExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetDetail = `${environment.apiUrl}${this.controller}/detail`;
  urlGetCount = `${environment.apiUrl}${this.controller}/count`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http.post<ITableServerResponseFilter>(`${this.urlGetGridList}`, filter).toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetGridExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetGridExportExcel}`, filter).toPromise();
  }

  getDetail(id: number): Promise<IDualControlDetail | undefined> {
    return this.http.get<IDualControlDetail>(`${this.urlGetDetail}/${id}`).toPromise();
  }

  getCount(): Promise<IDualControlCount | undefined> {
    const headers = new HttpHeaders().set('Skip-Loading', 'true');
    return this.http.get<IDualControlCount>(`${this.urlGetCount}`, { headers }).toPromise();
  }
}
