import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IWebMessageObj } from 'src/app/common/interfaces/webmessage-interface';
import { AbstractAccountService } from '../abstraction/abstract.account.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends AbstractAccountService {
  controller = `/manager/account`;
  urlGetAccountInformation = `${environment.apiUrl}${this.controller}/getinformation`;

  constructor(private http: HttpClient) {
    super();
  }

  getInformation(
    routingNumber: string,
    accountNumber: string
  ): Promise<IWebMessageObj | undefined> {
    return this.http
      .get<IWebMessageObj>(`${this.urlGetAccountInformation}/${routingNumber}/${accountNumber}`)
      .toPromise();
  }
}
