export class RfpSentStatusCodes {
  public static readonly Initiated = 'Initiated';
  public static readonly Received = 'Received';
  public static readonly Presented = 'Presented';
  public static readonly Accepted = 'Accepted';
  public static readonly AcceptedPaymentFailed = 'Accepted - Payment Failed';
  public static readonly Paid = 'Paid';
  public static readonly Denied = 'Denied';
  public static readonly Canceled = 'Canceled';
  public static readonly Expired = 'Expired';
}
