import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  template: `
    <div class="overlay">
      <div class="loader">
        <svg viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
      </div>
    </div>
  `,
  styleUrls: ['./loading-overlay.component.css'],
})
export class LoadingOverlayComponent {}
