import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AbstractCycleDateService } from '../abstraction/abstract.cycle-date.service';

@Injectable({
  providedIn: 'root',
})
export class MockCycleDateService extends AbstractCycleDateService {
  private getCurrentCycleDateJson = 'assets/mocks/cycledate/getCurrentCycleDate.json';

  constructor(private http: HttpClient) {
    super();
  }

  getCurrentCycleDate(): Promise<string | undefined> {
    return lastValueFrom(this.http.get<string>(this.getCurrentCycleDateJson));
  }
}
