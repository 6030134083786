import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { AbstractRFPSendService } from '../abstraction/abstract.rfp-send.service';
import { IRFPSend } from '../../interfaces/rfp-send/rfp-send.interface';
import { lastValueFrom } from 'rxjs';
import { ITableServerFilter } from '../../../common/interfaces/table/table-server-filter.inteface';
import { ITableServerResponseFilter } from '../../../common/interfaces/table/table-server-filter-response.interface';
import { IRFPSentDetails } from '../../interfaces/rfp-send/rfp-sent-details.interface';
import { IRFPSentList } from '../../interfaces/rfp-send/rfp-sent-list.interface';
import { RfpSentStatusCodes } from '../../constants/rfp-send/rfpstatus.constants';
import { IRFPRequestCancellation } from '../../interfaces/rfp-send/rfp-request-cancellation.interface';
import { RfpCancellationStatusCodes } from '../../constants/rfp-send/rfpcancellationstatus.constants';

@Injectable({
  providedIn: 'root',
})
export class MockRFPSendService extends AbstractRFPSendService {
  private rfpGenerateSuccessJson = 'assets/mocks/rfp-send/rfpGenerate_Success.json';
  private rfpGenerateErrorJson = 'assets/mocks/rfp-send/rfpGenerate_Error.json';
  private rfpGenerateZeroDollarSuccessJson =
    'assets/mocks/rfp-send/rfpGenerateZeroDollar_Success.json';
  private rfpGenerateZeroDollarErrorJson = 'assets/mocks/rfp-send/rfpGenerateZeroDollar_Error.json';
  private tableFilterJson = 'assets/mocks/rfp-send/tableFilter.json';
  private rfpStatusJson = 'assets/mocks/rfp-send/rfpStatus.json';
  private rfpCancellationsJson = 'assets/mocks/rfp-send/rfpCancellationStatus.json';
  private getById_RfpInitiatedCancellationNull =
    'assets/mocks/rfp-send/getById_RFPIntiatedCancellationNull.json';
  private getById_RfpReceivedCancellationNull =
    'assets/mocks/rfp-send/getById_RFPReceivedCancellationNull.json';
  private getById_RfpPresentedCancellationNull =
    'assets/mocks/rfp-send/getById_RFPPresentedCancellationNull.json';
  private getById_RfpDeniedCancellationNull =
    'assets/mocks/rfp-send/getById_RFPDeniedCancellationNull.json';
  private getById_RfpExpiredCancellationNull =
    'assets/mocks/rfp-send/getById_RFPExpiredCancellationNull.json';
  private getById_RfpAcceptedCancellationNull =
    'assets/mocks/rfp-send/getById_RFPAcceptedCancellationNull.json';
  private getById_RfpPaidCancellationNull =
    'assets/mocks/rfp-send/getById_RFPPaidCancellationNull.json';
  private rfpRequestCancelReasonCode = 'assets/mocks/rfp-send/rfpRequestCancelReasonCode.json';
  private rfpCancellationRequest_Error = 'assets/mocks/rfp-send/rfpCancellationRequest_Error.json';
  private rfpCancellationRequest_Success =
    'assets/mocks/rfp-send/rfpCancellationRequest_Success.json';
  private getById_RfpPresentedCancellationInitiated =
    'assets/mocks/rfp-send/getById_RfpPresentedCancellationInitiated.json';
  private getById_RfpCanceledCancellationAccepted =
    'assets/mocks/rfp-send/getById_RfpCanceledCancellationAccepted.json';
  private getById_RfpAcceptedCancellationDenied =
    'assets/mocks/rfp-send/getById_RfpAcceptedCancellationDenied.json';
  private getById_RfpPaidCancellationDenied =
    'assets/mocks/rfp-send/getById_RfpPaidCancellationDenied.json';
  private getById_RfpAcceptedFailedPaymentCancellationNull =
    'assets/mocks/rfp-send/getById_RfpAcceptedFailedPaymentCancellationNull.json';

  constructor(private http: HttpClient) {
    super();
  }

  rfpGenerate(rfp: IRFPSend): Promise<IWebMessage | undefined> {
    if (rfp.remittanceInfo == 'Error') {
      return lastValueFrom(this.http.get<any>(this.rfpGenerateErrorJson));
    }

    return lastValueFrom(this.http.get<any>(this.rfpGenerateSuccessJson));
  }

  rfpGenerateZeroDollar(rfp: IRFPSend): Promise<IWebMessage | undefined> {
    if (rfp.remittanceInfo == 'Error') {
      return lastValueFrom(this.http.get<any>(this.rfpGenerateZeroDollarErrorJson));
    }

    return lastValueFrom(this.http.get<any>(this.rfpGenerateZeroDollarSuccessJson));
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  async tableFilterSingleRecordById(messageId: string): Promise<IRFPSentList | undefined> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId) as IRFPSentList;
    return original;
  }

  rfpStatusList(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpStatusJson));
  }

  rfpCancellationStatusList(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpCancellationsJson));
  }

  rfpRequestCancelReasonCodes(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.rfpRequestCancelReasonCode));
  }

  rfpRequestCancellation(
    rfpCancellationRequest: IRFPRequestCancellation
  ): Promise<IWebMessage | undefined> {
    if (rfpCancellationRequest.additionalInformation == 'Error') {
      return lastValueFrom(this.http.get<any>(this.rfpCancellationRequest_Error));
    }

    return lastValueFrom(this.http.get<any>(this.rfpCancellationRequest_Success));
  }

  async getDetail(messageId: string): Promise<IRFPSentDetails | undefined> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId) as IRFPSentList;

    if (original.rfpStatus == RfpSentStatusCodes.Initiated && original.cancellationStatus == null)
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpInitiatedCancellationNull)
      );

    if (original.rfpStatus == RfpSentStatusCodes.Received && original.cancellationStatus == null)
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpReceivedCancellationNull)
      );

    if (original.rfpStatus == RfpSentStatusCodes.Presented && original.cancellationStatus == null)
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpPresentedCancellationNull)
      );

    if (original.rfpStatus == RfpSentStatusCodes.Denied && original.cancellationStatus == null)
      return lastValueFrom(this.http.get<IRFPSentDetails>(this.getById_RfpDeniedCancellationNull));

    if (original.rfpStatus == RfpSentStatusCodes.Expired && original.cancellationStatus == null)
      return lastValueFrom(this.http.get<IRFPSentDetails>(this.getById_RfpExpiredCancellationNull));

    if (original.rfpStatus == RfpSentStatusCodes.Accepted && original.cancellationStatus == null)
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpAcceptedCancellationNull)
      );

    if (original.rfpStatus == RfpSentStatusCodes.Paid && original.cancellationStatus == null)
      return lastValueFrom(this.http.get<IRFPSentDetails>(this.getById_RfpPaidCancellationNull));

    if (
      original.rfpStatus == RfpSentStatusCodes.AcceptedPaymentFailed &&
      original.cancellationStatus == null
    )
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpAcceptedFailedPaymentCancellationNull)
      );

    if (
      original.rfpStatus == RfpSentStatusCodes.Presented &&
      original.cancellationStatus == RfpCancellationStatusCodes.Initiated
    )
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpPresentedCancellationInitiated)
      );

    if (
      original.rfpStatus == RfpSentStatusCodes.Canceled &&
      original.cancellationStatus == RfpCancellationStatusCodes.Accepted
    )
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpCanceledCancellationAccepted)
      );
    if (original.rfpStatus == RfpSentStatusCodes.Paid && original.cancellationStatus != null)
      return lastValueFrom(this.http.get<IRFPSentDetails>(this.getById_RfpPaidCancellationDenied));

    if (original.cancellationStatus == RfpCancellationStatusCodes.Denied)
      return lastValueFrom(
        this.http.get<IRFPSentDetails>(this.getById_RfpAcceptedCancellationDenied)
      );
    return undefined;
  }

  validateRtnRequestForPaymentReceiveAllowed(
    routingNumber: string
  ): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }
}
