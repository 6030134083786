import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { lastValueFrom } from 'rxjs';
import { AbstractConfigurationReviewStatusService } from '../abstraction/abstract.configuration-review-status.service';
import { IDualControlDetail } from '../../interfaces/configuration/dual-control-detail.interface';
import { IDualControlCount } from '../../interfaces/configuration/dual-control-count.interface';

@Injectable({
  providedIn: 'root',
})
export class MockConfigurationReviewStatusService extends AbstractConfigurationReviewStatusService {
  private tableFilterJson =
    'assets/mocks/dualcontrol/pending-changes-review-status/tableFilter.json';
  private getDetailApprovedJson =
    'assets/mocks/dualcontrol/pending-changes-review-status/getDetailApproved.json';
  private getDetailRejectedJson =
    'assets/mocks/dualcontrol/pending-changes-review-status/getDetailRejected.json';
  private getDetailConflictedJson =
    'assets/mocks/dualcontrol/pending-changes-review-status/getDetailConflicted.json';
  private getDetailPendingJson =
    'assets/mocks/dualcontrol/pending-changes-review-status/getDetailPending.json';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  async getDetail(id: number): Promise<IDualControlDetail | undefined> {
    if (id == 1)
      return lastValueFrom(this.http.get<IDualControlDetail>(this.getDetailApprovedJson));

    if (id == 2)
      return lastValueFrom(this.http.get<IDualControlDetail>(this.getDetailRejectedJson));

    if (id == 3)
      return lastValueFrom(this.http.get<IDualControlDetail>(this.getDetailConflictedJson));

    if (id == 4) return lastValueFrom(this.http.get<IDualControlDetail>(this.getDetailPendingJson));
    return lastValueFrom(this.http.get<IDualControlDetail>(this.getDetailApprovedJson));
  }

  getCount(): Promise<IDualControlCount | undefined> {
    return Promise.resolve({ count: 1 } as IDualControlCount);
  }
}
