import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITableService } from 'src/app/common/interfaces/table/table-service.interface';
import { environment } from 'src/environments/environment';
import { AbstractNotificationService } from '../abstraction/abstract.notification.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends AbstractNotificationService {
  urlGetNotificationGrid = environment.apiUrl + '/manager/notification/grid';
  urlGetNotificationExportCSV = environment.apiUrl + '/manager/notification/gridExportCSV';
  urlGetNotificationExportExcel = environment.apiUrl + '/manager/notification/gridExportExcel';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetNotificationGrid}`, filter)
      .toPromise();
  }
  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetNotificationExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }
  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetNotificationExportExcel}`, filter).toPromise();
  }
}
