import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { AbstractAccountActivityCorrespondentService } from '../abstraction/abstract.account-activity-correspondent.service';

@Injectable({
  providedIn: 'root',
})
export class AccountActivityCorrespondentService extends AbstractAccountActivityCorrespondentService {
  controller = `/manager/accountactivitycorrespondent`;
  urlGetAccountActivityGrid = `${environment.apiUrl}${this.controller}/grid`;
  urlGetAccountActivityExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetAccountActivityExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetAccountActivityGrid}`, filter)
      .toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetAccountActivityExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetAccountActivityExportExcel}`, filter).toPromise();
  }
}
