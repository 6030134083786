import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AbstractRFPSendService } from '../abstraction/abstract.rfp-send.service';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IRFPSend } from '../../interfaces/rfp-send/rfp-send.interface';
import { ITableServerResponseFilter } from '../../../common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from '../../../common/interfaces/table/table-server-filter.inteface';
import { IRFPSentDetails } from '../../interfaces/rfp-send/rfp-sent-details.interface';
import { IRFPRequestCancellation } from '../../interfaces/rfp-send/rfp-request-cancellation.interface';
import { IRFPSentList } from '../../interfaces/rfp-send/rfp-sent-list.interface';

@Injectable({
  providedIn: 'root',
})
export class RFPSendService extends AbstractRFPSendService {
  controller = `/manager/rfp-send`;
  urlPostRFPSend = `${environment.apiUrl}${this.controller}/send`;
  urlPostRFPZeroDollarSend = `${environment.apiUrl}${this.controller}/sendZeroDollar`;
  urlGetRfpStatusList = `${environment.apiUrl}${this.controller}/rfpStatusList`;
  urlGetCancellationStatusList = `${environment.apiUrl}${this.controller}/cancellationstatuslist`;
  urlGetSendRfpList = `${environment.apiUrl}${this.controller}/grid`;
  urlGetDetail = `${environment.apiUrl}${this.controller}/detail`;
  urlGetRequestCancelReasonCodes = `${environment.apiUrl}${this.controller}/requestcancelreasoncodes`;
  urlPostRequestCancellation = `${environment.apiUrl}${this.controller}/requestcancelation`;
  urlGetValidateRtnRequestForPaymentReceiveAllowed = `${environment.apiUrl}${this.controller}/validateRtnRequestForPaymentReceiveAllowed`;

  urlGetSentRfpListExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  urlGetSentRfpListExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetSingleRecordRfpSentList = `${environment.apiUrl}${this.controller}/gridSingleRecord`;

  constructor(private http: HttpClient) {
    super();
  }

  rfpGenerate(rfp: IRFPSend): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostRFPSend}`, rfp).toPromise();
  }

  rfpGenerateZeroDollar(rfp: IRFPSend): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostRFPZeroDollarSend}`, rfp).toPromise();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetSendRfpList}`, filter)
      .toPromise();
  }
  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetSentRfpListExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }
  tableExportExcel(filter: ITableServerFilter): Promise<any> {
    return this.http.post(`${this.urlGetSentRfpListExportExcel}`, filter).toPromise();
  }

  tableFilterSingleRecordById(messageId: string): Promise<IRFPSentList | undefined> {
    return this.http
      .get<IRFPSentList>(`${this.urlGetSingleRecordRfpSentList}/${messageId}`)
      .toPromise();
  }

  rfpStatusList(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetRfpStatusList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  rfpCancellationStatusList(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetCancellationStatusList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  getDetail(messageId: string): Promise<IRFPSentDetails | undefined> {
    return this.http.get<IRFPSentDetails>(`${this.urlGetDetail}/${messageId}`).toPromise();
  }

  rfpRequestCancelReasonCodes(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetRequestCancelReasonCodes}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  rfpRequestCancellation(
    rfpCancellationRequest: IRFPRequestCancellation
  ): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostRequestCancellation}`, rfpCancellationRequest)
      .toPromise();
  }

  validateRtnRequestForPaymentReceiveAllowed(
    routingNumber: string
  ): Promise<IWebMessage | undefined> {
    return this.http
      .get<IWebMessage>(`${this.urlGetValidateRtnRequestForPaymentReceiveAllowed}/${routingNumber}`)
      .toPromise();
  }
}
