import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/layout/service/util.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private util: UtilService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (environment.useMock) {
      return true;
    }

    var jwt = this.util.getCookieByName(environment.jwtCookieName);

    if (!jwt) {
      this.router.navigate(['unauthorized']); // Assuming 'login' is your login route
      return true;
    }

    return true;
  }
}
