export class RfpStatusCodes {
  public static readonly DecisionPending = 'Decision Pending';
  public static readonly Denied = 'Denied';
  public static readonly AcceptedScheduled = 'Accepted - Scheduled';
  public static readonly AcceptedPaid = 'Accepted - Paid';
  public static readonly AcceptedFailedPayment = 'Accepted - Failed Payment';
  public static readonly Canceled = 'Canceled';
  public static readonly Expired = 'Expired';
  public static readonly AcceptedInitiated = 'Accepted - Initiated';
}
