import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MenuChangeEvent } from '../api/menuchangeevent';

export enum MenuServiceEvent {
  UpdateMenu,
  UpdateBadgePendingChanges,
  UpdateBadgeReviewStatus,
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private eventSubject = new Subject<{ ev: MenuServiceEvent; data: any }>();

  private menuSource = new Subject<MenuChangeEvent>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(event: MenuChangeEvent) {
    this.menuSource.next(event);
  }

  reset() {
    this.resetSource.next(true);
  }

  fireEvent(ev: MenuServiceEvent, data: any) {
    this.eventSubject.next({ ev, data });
  }

  onEvent(ev: MenuServiceEvent): Observable<any> {
    return this.eventSubject.asObservable().pipe(
      filter(event => event.ev === ev),
      map(event => event.data)
    );
  }

  unsubcribe() {
    this.eventSubject.unsubscribe();
  }
}
