<div *ngIf="model.length <= 0">
  <p-skeleton styleClass="mb-3 ml-3 mt-4 w-5rem"></p-skeleton>
  <p-skeleton styleClass="mb-4 ml-5 mt-4 w-10rem"></p-skeleton>
  <p-skeleton styleClass="mb-4 ml-5 w-10rem"></p-skeleton>
  <p-skeleton styleClass="mb-4 ml-5 w-10rem"></p-skeleton>
  <p-skeleton styleClass="mb-4 ml-5 w-10rem"></p-skeleton>
  <p-skeleton styleClass="mb-3 ml-3 mt-4 w-5rem"></p-skeleton>
  <p-skeleton styleClass="mb-4 ml-5 mt-4 w-10rem"></p-skeleton>
</div>
<ul class="layout-menu">
  <ng-container *ngFor="let item of model; let i = index">
    <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
    <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>
</ul>
