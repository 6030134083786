import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilService } from 'src/app/layout/service/util.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserTypeService {
  constructor(
    private util: UtilService,
    private jwtHelper: JwtHelperService
  ) {}

  isCorrespondent() {
    if (environment.useMock) {
      const userType = sessionStorage.getItem('mockUserType');
      return userType !== 'Respondent';
    }

    var jwt = this.util.getCookieByName(environment.jwtCookieName);
    if (jwt) {
      const decodedJwt = this.jwtHelper.decodeToken(jwt);
      return decodedJwt.ssoRequestUserKind != 'Respondent';
    }
    return true;
  }

  isStandAlone() {
    var jwt = this.util.getCookieByName(environment.jwtCookieName);
    if (jwt) {
      const decodedJwt = this.jwtHelper.decodeToken(jwt);
      return (
        decodedJwt.InstitutionType !== undefined && decodedJwt.InstitutionType === 'StandAlone'
      );
    }
    return false;
  }
}
