import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { ITableService } from '../../../common/interfaces/table/table-service.interface';
import { IRFPZeroDollarSentDetails } from '../../interfaces/rfp-send/rfp-zero-dollar-sent-details.interface';
import { IRFZeroDollarSentList } from '../../interfaces/rfp-send/rfp-zero-dollar-sent-list.interface';

export abstract class AbstractRFPSendZeroDollarService extends ITableService {
  abstract rfpStatusList(): Promise<any>;
  abstract tableFilterSingleRecordById(
    messageId: string
  ): Promise<IRFZeroDollarSentList | undefined>;
  abstract getDetail(messageId: string): Promise<IRFPZeroDollarSentDetails | undefined>;
}
