import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  IAccountVerificationRequest,
  IReturnApproveDecision,
  IReturnDecision,
} from '../../interfaces/return-list/return-decision.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IReturnList } from '../../interfaces/return-list/return-list.interface';
import { AbstractReturnListService } from '../abstraction/abstract.return-list.service';

@Injectable({
  providedIn: 'root',
})
export class ReturnListService extends AbstractReturnListService {
  controller = `/manager/returnlist`;
  urlGetReturnList = `${environment.apiUrl}${this.controller}/grid`;
  urlGetReturnListExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  urlGetReturnListExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetSingleRecordReturnList = `${environment.apiUrl}${this.controller}/gridSingleRecord`;
  urlGetReturnDetailGrid = `${environment.apiUrl}${this.controller}/returnListDetail`;
  urlPostApprove = `${environment.apiUrl}${this.controller}/returnApprove`;
  urlPostDeny = `${environment.apiUrl}${this.controller}/returnDeny`;
  urlGetReasonDenyList = `${environment.apiUrl}${this.controller}/returnListDenyReasonList`;
  urlGetReturnStatusList = `${environment.apiUrl}${this.controller}/returnReasonList`;
  urlPostAccountVerification = `${environment.apiUrl}${this.controller}/accountverification`;
  urlGetPendingDecisionReturnCountList = `${environment.apiUrl}${this.controller}/pendingdecisionreturncount`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetReturnList}`, filter)
      .toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetReturnListExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetReturnListExportExcel}`, filter).toPromise();
  }

  tableFilterSingleRecordById(messageId: string): Promise<IReturnList | undefined> {
    return this.http
      .get<IReturnList>(`${this.urlGetSingleRecordReturnList}/${messageId}`)
      .toPromise();
  }

  reasonDenyList(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetReasonDenyList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  returnList(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetReturnStatusList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  getById(messageId: string): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetReturnDetailGrid}/${messageId}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  getPendingDecisionReturnCount(): Promise<number | undefined> {
    return this.http.get<number>(`${this.urlGetPendingDecisionReturnCountList}`).toPromise();
  }

  approve(decision: IReturnApproveDecision): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostApprove}`, decision).toPromise();
  }

  deny(decision: IReturnDecision): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostDeny}`, decision).toPromise();
  }

  accountVerification(detail: IAccountVerificationRequest): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostAccountVerification}`, detail).toPromise();
  }
}
