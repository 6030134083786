import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISettlementTransaction } from '../../interfaces/settlement/settlement-transaction.interface';
import { AbstractSettlementService } from '../abstraction/abstract.settlement.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockSettlementService extends AbstractSettlementService {
  private getTransactionsByRespondentAndCycleJson =
    'assets/mocks/settlement/getTransactionsByRespondentAndCycle.json';

  constructor(private http: HttpClient) {
    super();
  }

  getTransactionsByRespondentAndCycle(
    respondentRtn: string,
    cycleDate: string
  ): Promise<ISettlementTransaction[] | undefined> {
    return lastValueFrom(
      this.http.get<ISettlementTransaction[]>(this.getTransactionsByRespondentAndCycleJson)
    );
  }
}
