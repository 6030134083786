import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IWebMessageObj } from 'src/app/common/interfaces/webmessage-interface';
import { AbstractAccountService } from '../abstraction/abstract.account.service';

@Injectable({
  providedIn: 'root',
})
export class MockAccountService extends AbstractAccountService {
  constructor(private http: HttpClient) {
    super();
  }

  getInformation(
    routingNumber: string,
    accountNumber: string
  ): Promise<IWebMessageObj | undefined> {
    throw new Error('Method not implemented.');
  }
}
