import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IMasterAccount } from '../../interfaces/master-account/master-account.interface';
import { AbstractMasterAccountService } from '../abstraction/abstract.master-account.service';

@Injectable({
  providedIn: 'root',
})
export class MasterAccountService extends AbstractMasterAccountService {
  controller = `/manager/masteraccount`;
  urlGetBalanceInformation = `${environment.apiUrl}${this.controller}/getbalance`;

  constructor(private http: HttpClient) {
    super();
  }

  getMasterAccountBalanceInformation(
    corrsRoutingNumber: string | undefined
  ): Promise<IMasterAccount | undefined> {
    return this.http
      .get<IMasterAccount>(`${this.urlGetBalanceInformation}/${corrsRoutingNumber}`)
      .toPromise();
  }
}
