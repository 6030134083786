<div class="layout-topbar">
  <div class="layout-topbar-start">
    <a class="layout-topbar-logo" routerLink="/fednow/account-activity">
      <img
        [ngClass]="{
          'layout-topbar-logo-slim lg: w-10 md:w-10 sm:w-10': isSlim,
          'layout-topbar-logo-full lg: w-6 md: w-2': !isSlim,
        }"
        [src]="
          isSlim ? '../../assets/images/favicon.png' : '../../assets/images/AptysSolutionsLogo.png'
        "
        title="Aptys Solutions" />
    </a>
    <a #menuButton class="layout-menu-button" (click)="onMenuButtonClick()" pRipple>
      <i
        class="pi"
        [ngClass]="
          layoutService.state.staticMenuDesktopInactive ? 'pi-chevron-left' : 'pi-chevron-right'
        "></i>
    </a>

    <a
      #mobileMenuButton
      class="layout-topbar-mobile-button"
      (click)="onMobileTopbarMenuButtonClick()"
      pRipple>
      <i class="pi pi-ellipsis-v"></i>
    </a>
  </div>

  <div class="layout-topbar-end">
    <div class="layout-topbar-actions-start"></div>
    <div class="layout-topbar-actions-end">
      <p-splitButton
        [label]="currentUserType"
        icon="pi pi-check-circle"
        [model]="items"
        *ngIf="useMock"></p-splitButton>
      <ul class="layout-topbar-items" *ngIf="!useMock">
        <p class="center logged-in-user">{{ this.fullName }}</p>
        <li>
          <a
            pStyleClass="@next"
            enterClass="ng-hidden"
            enterActiveClass="px-scalein"
            leaveToClass="ng-hidden"
            leaveActiveClass="px-fadeout"
            [hideOnOutsideClick]="true"
            pRipple>
            <img src="assets/images/userprofile.png" alt="profile" class="w-2rem h-2rem" />
          </a>
          <div class="ng-hidden">
            <ul class="list-none p-0 m-0">
              <li>
                <a
                  class="cursor-pointer flex align-items-center hover:surface-hover transition-duration-150 transition-all px-3 py-2 font-semibold"
                  (click)="logout()"
                  pRipple>
                  <!-- <i class="pi pi-power-off mr-2"></i> -->
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li></li>
      </ul>
    </div>
  </div>
</div>
