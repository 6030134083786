import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAccountVerificationRequest,
  IReturnApproveDecision,
  IReturnDecision,
} from '../../interfaces/return-list/return-decision.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IReturnList } from '../../interfaces/return-list/return-list.interface';
import { AbstractReturnListService } from '../abstraction/abstract.return-list.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockReturnListService extends AbstractReturnListService {
  private tableFilterJson = 'assets/mocks/returnlist/tableFilter.json';
  private returnListJson = 'assets/mocks/returnlist/returnList.json';
  private reasonDenyListJson = 'assets/mocks/returnlist/reasonDenyList.json';
  private getByIdPendingJson = 'assets/mocks/returnlist/getById_Pending.json';
  private getByIdApprovedJson = 'assets/mocks/returnlist/getById_Approved.json';
  private getByIdDeniedJson = 'assets/mocks/returnlist/getById_Denied.json';
  private approveJson = 'assets/mocks/returnlist/approve.json';
  private denyJson = 'assets/mocks/returnlist/deny.json';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableFilterSingleRecordById(messageId: string): Promise<IReturnList | undefined> {
    throw new Error('Method not implemented.');
  }

  reasonDenyList(): Promise<any> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.reasonDenyListJson));
  }

  returnList(): Promise<any> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.returnListJson));
  }

  async getById(messageId: string): Promise<any> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId);

    if (original.returnStatus == 'Return Denied')
      return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.getByIdDeniedJson));
    if (original.returnStatus == 'Return Approved')
      return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.getByIdApprovedJson));
    if (original.returnStatus == 'Decision Pending')
      return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.getByIdPendingJson));
  }

  getPendingDecisionReturnCount(): Promise<number | undefined> {
    throw new Error('Method not implemented.');
  }

  approve(decision: IReturnApproveDecision): Promise<IWebMessage | undefined> {
    return lastValueFrom(this.http.get<IWebMessage>(this.approveJson));
  }

  deny(decision: IReturnDecision): Promise<IWebMessage | undefined> {
    return lastValueFrom(this.http.get<IWebMessage>(this.denyJson));
  }

  accountVerification(detail: IAccountVerificationRequest): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }
}
