import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AbstractSessionService } from '../abstraction/abstract.session.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends AbstractSessionService {
  controller = `/manager/session`;
  urlPostLogout = `${environment.apiUrl}${this.controller}/logout`;

  constructor(private http: HttpClient) {
    super();
  }

  logout(): Promise<void> {
    return this.http.post<void>(this.urlPostLogout, {}).toPromise();
  }
}
