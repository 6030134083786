import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { lastValueFrom } from 'rxjs';
import { AbstractConfigurationPendingChangesService } from '../abstraction/abstract.configuration-pending-changes.service';
import { IDualControlDetail } from '../../interfaces/configuration/dual-control-detail.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IDualControlDecision } from '../../interfaces/configuration/dual-control-decision.interface';
import { IDualControlCount } from '../../interfaces/configuration/dual-control-count.interface';

@Injectable({
  providedIn: 'root',
})
export class MockConfigurationPendingChangesService extends AbstractConfigurationPendingChangesService {
  private tableFilterJson =
    'assets/mocks/dualcontrol/configuration-pending-changes/tableFilter.json';
  private getDetailJson = 'assets/mocks/dualcontrol/configuration-pending-changes/getDetail.json';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  async getDetail(id: number): Promise<IDualControlDetail | undefined> {
    return lastValueFrom(this.http.get<IDualControlDetail>(this.getDetailJson));
  }

  getCount(): Promise<IDualControlCount | undefined> {
    return Promise.resolve({ count: 2 } as IDualControlCount);
  }

  postValidate(decision: IDualControlDecision): Promise<IWebMessage | undefined> {
    const webMessage: IWebMessage = {
      status: 'Success',
      message: 'Validation successful.',
      object: null,
    };

    return Promise.resolve(webMessage);
  }
  postReject(decision: IDualControlDecision): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }
  postApprove(decision: IDualControlDecision): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }
}
