import { Component, OnInit } from '@angular/core';
import { IInstitution } from '../fednow/interfaces/institution/institution.interface';
import { LayoutService } from './service/app.layout.service';
import { AbstractInstitutionService } from '../fednow/service/abstraction/abstract.institution.service';

@Component({
  selector: 'app-userinfo',
  templateUrl: './app.userinfo.compoment.html',
  styleUrls: ['./app.userinfo.compoment.css'],
})
export class AppUserInfoComponent implements OnInit {
  data: IInstitution | undefined;
  truncatedName: string = '';
  constructor(
    private institutionService: AbstractInstitutionService,
    public layoutService: LayoutService
  ) {}

  async ngOnInit(): Promise<void> {
    this.data = await this.institutionService.getInformation();
    if (this.data && this.data.name && this.data.name.length > 20) {
      this.truncatedName = this.data.name.substring(0, 20) + '...';
    } else {
      this.truncatedName = this.data?.name ? this.data?.name : '';
    }
  }

  get isSlim() {
    return this.layoutService.isSlim();
  }

  get isMobile() {
    return this.layoutService.isMobile();
  }
}
