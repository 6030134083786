import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { BadgeModule } from 'primeng/badge';

import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppLayoutComponent } from './app.layout.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppUserInfoComponent } from './app.userinfo.compoment';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  declarations: [
    AppLayoutComponent,
    AppBreadcrumbComponent,
    AppTopbarComponent,
    AppMenuComponent,
    AppSidebarComponent,
    AppMenuitemComponent,
    AppUserInfoComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StyleClassModule,
    TooltipModule,
    MenuModule,
    ToastModule,
    SkeletonModule,
    BadgeModule,
    SplitButtonModule,
  ],
  providers: [MessageService],
})
export class AppLayoutModule {}
