import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IMasterAccount } from '../../interfaces/master-account/master-account.interface';
import { AbstractMasterAccountService } from '../abstraction/abstract.master-account.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockMasterAccountService extends AbstractMasterAccountService {
  private getMasterAccountBalanceInformationJson =
    'assets/mocks/masteraccount/getMasterAccountBalanceInformation.json';

  constructor(private http: HttpClient) {
    super();
  }

  getMasterAccountBalanceInformation(
    corrsRoutingNumber: string | undefined
  ): Promise<IMasterAccount | undefined> {
    return lastValueFrom(
      this.http.get<IMasterAccount>(this.getMasterAccountBalanceInformationJson)
    );
  }
}
