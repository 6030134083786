import { Provider } from '@angular/core';
import { AbstractInstitutionService } from './fednow/service/abstraction/abstract.institution.service';
import { environment } from 'src/environments/environment';
import { MockInstitutionService } from './fednow/service/mock/mock.institution.service';
import { InstitutionService } from './fednow/service/http/institution.service';
import { CycleDateService } from './fednow/service/http/cycle-date.service';
import { AbstractCycleDateService } from './fednow/service/abstraction/abstract.cycle-date.service';
import { MockCycleDateService } from './fednow/service/mock/mock.cycle-date.service';
import { AbstractAccountActivityCorrespondentService } from './fednow/service/abstraction/abstract.account-activity-correspondent.service';
import { AccountActivityCorrespondentService } from './fednow/service/http/account-activity-correspondent.service';
import { AbstractAccountActivityRespondentService } from './fednow/service/abstraction/abstract.account-activity-respondent.service';
import { AccountActivityRespondentService } from './fednow/service/http/account-activity-respondent.service';
import { AbstractAccountService } from './fednow/service/abstraction/abstract.account.service';
import { AccountService } from './fednow/service/http/account.service';
import { AbstractMasterAccountService } from './fednow/service/abstraction/abstract.master-account.service';
import { MasterAccountService } from './fednow/service/http/master-account.service';
import { AbstractRespondentService } from './fednow/service/abstraction/abstract.respondent.service';
import { RespondentService } from './fednow/service/http/respondent.service';
import { AbstractReturnInitiateService } from './fednow/service/abstraction/abstract.return-initiated.service';
import { ReturnInitiateService } from './fednow/service/http/return-initiated.service';
import { AbstractReturnListService } from './fednow/service/abstraction/abstract.return-list.service';
import { ReturnListService } from './fednow/service/http/return-list.service';
import { AbstractSendPaymentService } from './fednow/service/abstraction/abstract.send-payment.service';
import { SendPaymentService } from './fednow/service/http/send-payment.service';
import { AbstractSessionService } from './fednow/service/abstraction/abstract.session.service';
import { SessionService } from './fednow/service/http/session.service';
import { AbstractSettlementService } from './fednow/service/abstraction/abstract.settlement.service';
import { SettlementService } from './fednow/service/http/settlement.service';
import { AbstractTransactionService } from './fednow/service/abstraction/abstract.transaction.service';
import { TransactionService } from './fednow/service/http/transaction.service';
import { MockAccountActivityRespondentService } from './fednow/service/mock/mock.account-activity-respondent.service';
import { MockTransactionService } from './fednow/service/mock/mock.transaction.service';
import { MockReturnListService } from './fednow/service/mock/mock.return-list.service';
import { MockReturnInitiateService } from './fednow/service/mock/mock.return-initiated.service';
import { AbstractNotificationService } from './fednow/service/abstraction/abstract.notification.service';
import { NotificationService } from './fednow/service/http/notification.service';
import { MockNotificationService } from './fednow/service/mock/mock.notification.service';
import { MockRespondentService } from './fednow/service/mock/mock.respondent.service';
import { MockAccountService } from './fednow/service/mock/mock.account.service';
import { MockSendPaymentService } from './fednow/service/mock/mock.send-payment.service';
import { MockSessionService } from './fednow/service/mock/mock.session.service';
import { MockSettlementService } from './fednow/service/mock/mock.settlement.service';
import { MockMasterAccountService } from './fednow/service/mock/mock.master-account.service';
import { MockAccountActivityCorrespondentService } from './fednow/service/mock/mock.account-activity-correspondent.service';
import { RFPReceiveService } from './fednow/service/http/rfp-receive.service';
import { MockRFPReceiveService } from './fednow/service/mock/mock.rfp-receive.service';
import { AbstractRFPReceiveService } from './fednow/service/abstraction/abstract.rfp-receive.service';
import { AbstractRFPSendService } from './fednow/service/abstraction/abstract.rfp-send.service';
import { RFPSendService } from './fednow/service/http/rfp-send.service';
import { MockRFPSendService } from './fednow/service/mock/mock.rfp-send.service';
import { AbstractRFPSendZeroDollarService } from './fednow/service/abstraction/abstract.rfp-send-zero-dollar.service';
import { RFPZeroDollarSendService } from './fednow/service/http/rfp-send-zero-dollar.service';
import { MockRFPZeroDollarSendService } from './fednow/service/mock/mock.rfp-send-zero-dollar.service';
import { AbstractGeneralService } from './fednow/service/abstraction/abstract.general.service';
import { MockGeneralService } from './fednow/service/mock/mock.general.service';
import { GeneralService } from './fednow/service/http/general.service';
import { AbstractConfigurationService } from './fednow/service/abstraction/abstract.configuration.service';
import { ConfigurationService } from './fednow/service/http/configuration.service';
import { AbstractConfigurationPendingChangesService } from './fednow/service/abstraction/abstract.configuration-pending-changes.service';
import { MockConfigurationPendingChangesService } from './fednow/service/mock/mock.configuration-pending-changes.service';
import { ConfigurationPendingChangesService } from './fednow/service/http/configuration-pending-changes.service';
import { AbstractConfigurationReviewStatusService } from './fednow/service/abstraction/abstract.configuration-review-status.service';
import { MockConfigurationReviewStatusService } from './fednow/service/mock/mock.configuration-review-status.service';
import { ConfigurationReviewStatusService } from './fednow/service/http/configuration-review-status.service';
import { MockConfigurationService } from './fednow/service/mock/mock.configuration.service';
import { AbstractAuditReviewService } from './fednow/service/abstraction/abstract.audit-review.service';
import { MockAuditReviewService } from './fednow/service/mock/mock.audit-review.service';
import { AuditReviewService } from './fednow/service/http/audit-review.service';

export const serviceProviders: Provider[] = [
  {
    provide: AbstractAccountActivityCorrespondentService,
    useClass: environment.useMock
      ? MockAccountActivityCorrespondentService
      : AccountActivityCorrespondentService,
  },
  {
    provide: AbstractAccountActivityRespondentService,
    useClass: environment.useMock
      ? MockAccountActivityRespondentService
      : AccountActivityRespondentService,
  },
  {
    provide: AbstractAccountService,
    useClass: environment.useMock ? MockAccountService : AccountService,
  },
  {
    provide: AbstractCycleDateService,
    useClass: environment.useMock ? MockCycleDateService : CycleDateService,
  },
  {
    provide: AbstractInstitutionService,
    useClass: environment.useMock ? MockInstitutionService : InstitutionService,
  },
  {
    provide: AbstractMasterAccountService,
    useClass: environment.useMock ? MockMasterAccountService : MasterAccountService,
  },
  {
    provide: AbstractRespondentService,
    useClass: environment.useMock ? MockRespondentService : RespondentService,
  },
  {
    provide: AbstractReturnInitiateService,
    useClass: environment.useMock ? MockReturnInitiateService : ReturnInitiateService,
  },
  {
    provide: AbstractReturnListService,
    useClass: environment.useMock ? MockReturnListService : ReturnListService,
  },
  {
    provide: AbstractSendPaymentService,
    useClass: environment.useMock ? MockSendPaymentService : SendPaymentService,
  },
  {
    provide: AbstractSessionService,
    useClass: environment.useMock ? MockSessionService : SessionService,
  },
  {
    provide: AbstractSettlementService,
    useClass: environment.useMock ? MockSettlementService : SettlementService,
  },
  {
    provide: AbstractTransactionService,
    useClass: environment.useMock ? MockTransactionService : TransactionService,
  },
  {
    provide: AbstractNotificationService,
    useClass: environment.useMock ? MockNotificationService : NotificationService,
  },
  {
    provide: AbstractRFPReceiveService,
    useClass: environment.useMock ? MockRFPReceiveService : RFPReceiveService,
  },
  {
    provide: AbstractRFPSendService,
    useClass: environment.useMock ? MockRFPSendService : RFPSendService,
  },
  {
    provide: AbstractRFPSendZeroDollarService,
    useClass: environment.useMock ? MockRFPZeroDollarSendService : RFPZeroDollarSendService,
  },
  {
    provide: AbstractGeneralService,
    useClass: environment.useMock ? MockGeneralService : GeneralService,
  },
  {
    provide: AbstractConfigurationService,
    useClass: environment.useMock ? MockConfigurationService : ConfigurationService,
  },
  {
    provide: AbstractConfigurationPendingChangesService,
    useClass: environment.useMock
      ? MockConfigurationPendingChangesService
      : ConfigurationPendingChangesService,
  },
  {
    provide: AbstractConfigurationReviewStatusService,
    useClass: environment.useMock
      ? MockConfigurationReviewStatusService
      : ConfigurationReviewStatusService,
  },
  {
    provide: AbstractAuditReviewService,
    useClass: environment.useMock ? MockAuditReviewService : AuditReviewService,
  },
];
