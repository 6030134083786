import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IReturnInitiate } from '../../interfaces/return-list/return-initiate.interface';
import { IInitiatedReturnDetails } from '../../interfaces/initiated-return/initiated-return-detail.interface';
import { IInitiatedReturnList } from '../../interfaces/initiated-return/initiated-return-list.interface';
import { AbstractReturnInitiateService } from '../abstraction/abstract.return-initiated.service';

@Injectable({
  providedIn: 'root',
})
export class ReturnInitiateService extends AbstractReturnInitiateService {
  controller = `/manager/initiatedreturn`;
  urlGetReturnList = `${environment.apiUrl}${this.controller}/grid`;
  urlGetReturnListExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  urlGetReturnListExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetSingleRecordReturnList = `${environment.apiUrl}${this.controller}/gridSingleRecord`;

  urlGetDetail = `${environment.apiUrl}${this.controller}/initiatedReturnDetail`;
  urlGetDetailFromTransaction = `${environment.apiUrl}${this.controller}/initiatedReturnDetailFromTransaction`;

  urlGetReasonInitiateReturnList = `${environment.apiUrl}${this.controller}/returninitiatelist`;
  urlGetFraudClassifierList = `${environment.apiUrl}${this.controller}/fraudclassifierslist`;
  urlPostInitiate = `${environment.apiUrl}${this.controller}/initiatereturn`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetReturnList}`, filter)
      .toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetReturnListExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetReturnListExportExcel}`, filter).toPromise();
  }

  tableFilterSingleRecordById(messageId: string): Promise<IInitiatedReturnList | undefined> {
    return this.http
      .get<IInitiatedReturnList>(`${this.urlGetSingleRecordReturnList}/${messageId}`)
      .toPromise();
  }

  getDetail(messageId: string): Promise<IInitiatedReturnDetails | undefined> {
    return this.http.get<IInitiatedReturnDetails>(`${this.urlGetDetail}/${messageId}`).toPromise();
  }

  getDetailFromTransaction(messageId: string): Promise<IInitiatedReturnDetails | undefined> {
    return this.http
      .get<IInitiatedReturnDetails>(`${this.urlGetDetailFromTransaction}/${messageId}`)
      .toPromise();
  }

  initiate(initiateReturn: IReturnInitiate): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostInitiate}`, initiateReturn).toPromise();
  }

  reasonInitiateReturnList(messageId: string): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetReasonInitiateReturnList}/${messageId}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  fraudClassifierList(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetFraudClassifierList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }
}
