import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractSessionService } from '../abstraction/abstract.session.service';

@Injectable({
  providedIn: 'root',
})
export class MockSessionService extends AbstractSessionService {
  constructor(private http: HttpClient) {
    super();
  }

  logout(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
