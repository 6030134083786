import { ITableService } from 'src/app/common/interfaces/table/table-service.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IReturnInitiate } from '../../interfaces/return-list/return-initiate.interface';
import { IInitiatedReturnDetails } from '../../interfaces/initiated-return/initiated-return-detail.interface';
import { IInitiatedReturnList } from '../../interfaces/initiated-return/initiated-return-list.interface';

export abstract class AbstractReturnInitiateService extends ITableService {
  abstract tableFilterSingleRecordById(
    messageId: string
  ): Promise<IInitiatedReturnList | undefined>;
  abstract getDetail(messageId: string): Promise<IInitiatedReturnDetails | undefined>;
  abstract getDetailFromTransaction(
    messageId: string
  ): Promise<IInitiatedReturnDetails | undefined>;
  abstract initiate(initiateReturn: IReturnInitiate): Promise<IWebMessage | undefined>;
  abstract reasonInitiateReturnList(messageId: string): Promise<any>;
  abstract fraudClassifierList(): Promise<any>;
}
