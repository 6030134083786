import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPayerInformation } from '../../interfaces/send-payment/send-payment.interface';
import { AbstractSendPaymentService } from '../abstraction/abstract.send-payment.service';

@Injectable({
  providedIn: 'root',
})
export class MockSendPaymentService extends AbstractSendPaymentService {
  constructor(private http: HttpClient) {
    super();
  }

  getPayerInformation(primaryRTN: string): Promise<IPayerInformation | undefined> {
    throw new Error('Method not implemented.');
  }
}
