import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { AbstractAccountActivityRespondentService } from '../abstraction/abstract.account-activity-respondent.service';

@Injectable({
  providedIn: 'root',
})
export class AccountActivityRespondentService extends AbstractAccountActivityRespondentService {
  controllerRespondent = `/manager/accountactivityrespondent`;

  urlGetAccountActivityRespondentGrid = `${environment.apiUrl}${this.controllerRespondent}/grid`;
  urlGetAccountActivityRespondentExportExcel = `${environment.apiUrl}${this.controllerRespondent}/gridExportExcel`;
  urlGetAccountActivityRespondentExportCSV = `${environment.apiUrl}${this.controllerRespondent}/gridExportCSV`;
  urlGetCurrentAccountActivityRespondent = `${environment.apiUrl}${this.controllerRespondent}/getcurrentnetactivityrespondent`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetAccountActivityRespondentGrid}`, filter)
      .toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetAccountActivityRespondentExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetAccountActivityRespondentExportExcel}`, filter).toPromise();
  }

  getCurrentNetActivity(): Promise<string | undefined> {
    return this.http.get<string>(`${this.urlGetCurrentAccountActivityRespondent}`).toPromise();
  }
}
