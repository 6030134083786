import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AbstractGeneralService } from '../abstraction/abstract.general.service';
import { IFilterDates } from '../../interfaces/general/filter-dates.interface';
@Injectable({
  providedIn: 'root',
})
export class GeneralService extends AbstractGeneralService {
  controller = `/manager/general`;
  urlGetFromToDate = `${environment.apiUrl}${this.controller}/getfromtoestdate`;

  constructor(private http: HttpClient) {
    super();
  }

  getFromToDate(): Promise<IFilterDates | undefined> {
    return this.http.get<IFilterDates>(`${this.urlGetFromToDate}`).toPromise();
  }
}
