import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { environment } from 'src/environments/environment';
import { ITransactionSend } from '../../interfaces/transaction/send-transaction.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { ITransactionDetail } from '../../interfaces/transaction/transaction-detail.interface';
import { AbstractTransactionService } from '../abstraction/abstract.transaction.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService extends AbstractTransactionService {
  controllerUrl = `/manager/transactions`;
  urlGetTransactionsList = `${environment.apiUrl}${this.controllerUrl}/grid`;
  urlGetTransactionsExportCSV = `${environment.apiUrl}${this.controllerUrl}/gridExportCSV`;
  urlGetTransactionsExportExcel = `${environment.apiUrl}${this.controllerUrl}/gridExportExcel`;
  urlGetSingleRecordReturnList = `${environment.apiUrl}${this.controllerUrl}/gridSingleRecord`;
  urlGetTransactionDetailGrid = `${environment.apiUrl}${this.controllerUrl}/transactionDetail`;
  urlGetReturnTransactionDetailGrid = `${environment.apiUrl}${this.controllerUrl}/transactionReturnDetail`;
  urlGetCurrentCycleTransactionsCount = `${environment.apiUrl}${this.controllerUrl}/currentcycletransactionscount`;
  urlPostSendTransaction = `${environment.apiUrl}${this.controllerUrl}/sendtransaction`;
  urlGetCategoryPurpose = `${environment.apiUrl}${this.controllerUrl}/categorypurposelist`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetTransactionsList}`, filter)
      .toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetTransactionsExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetTransactionsExportExcel}`, filter).toPromise();
  }

  tableFilterSingleRecordById(
    messageId: string,
    type: string
  ): Promise<ITransactionDetail | undefined> {
    return this.http
      .get<ITransactionDetail>(`${this.urlGetSingleRecordReturnList}/${messageId}/${type}`)
      .toPromise();
  }

  getCurrentCycleTransactionsCount(): Promise<number | undefined> {
    return this.http.get<number>(`${this.urlGetCurrentCycleTransactionsCount}`).toPromise();
  }

  getById(messageId: string, type: string): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetTransactionDetailGrid}/${messageId}/${type}`)
      .toPromise();
  }

  getReturnById(messageId: string, type: string): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetReturnTransactionDetailGrid}/${messageId}/${type}`)
      .toPromise();
  }

  sendTransaction(transaction: ITransactionSend): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostSendTransaction}`, transaction).toPromise();
  }

  categoryPurpose(): Promise<any> {
    return this.http.get<any>(`${this.urlGetCategoryPurpose}`).toPromise();
  }
}
