import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from 'src/app/layout/service/auth.service';

import { AppLayoutComponent } from './layout/app.layout.component';
import { HowtoLoginComponent as howtologin } from './common/howtologin/howtologin.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { LogoutComponent } from './common/logout/logout.component';
import { SmokeTestComponent } from './common/home/smoketest/smoketest.component';
import { NoonboardedComponent } from './common/noonboarded/noonboarded.component';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'fednow',
        data: { breadcrumb: 'FedNow' },
        loadChildren: () => import('./fednow/fednow.module').then(m => m.FedNowModule),
      },
      // { path: 'rtp', data: { breadcrumb: 'RTP' }, loadChildren: () => import('./rtp/rtp.module').then(m => m.RTPModule) }
    ],
  },
  {
    path: 'howtologin',
    component: howtologin,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'home/smoketest',
    component: SmokeTestComponent,
  },
  {
    path: 'noonboarded',
    component: NoonboardedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
