import { ITableService } from 'src/app/common/interfaces/table/table-service.interface';
import { IDualControlDetail } from '../../interfaces/configuration/dual-control-detail.interface';
import { IDualControlDecision } from '../../interfaces/configuration/dual-control-decision.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IDualControlCount } from '../../interfaces/configuration/dual-control-count.interface';

export abstract class AbstractConfigurationPendingChangesService extends ITableService {
  abstract getDetail(id: number): Promise<IDualControlDetail | undefined>;
  abstract getCount(): Promise<IDualControlCount | undefined>;
  abstract postValidate(decision: IDualControlDecision): Promise<IWebMessage | undefined>;
  abstract postReject(decision: IDualControlDecision): Promise<IWebMessage | undefined>;
  abstract postApprove(decision: IDualControlDecision): Promise<IWebMessage | undefined>;
}
