import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITransactionSend } from '../../interfaces/transaction/send-transaction.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { ITransactionDetail } from '../../interfaces/transaction/transaction-detail.interface';
import { AbstractTransactionService } from '../abstraction/abstract.transaction.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockTransactionService extends AbstractTransactionService {
  private tableFilterJson = 'assets/mocks/transaction/tableFilter.json';
  private getByIdJson = 'assets/mocks/transaction/getById.json';
  private getByIdCreditJson = 'assets/mocks/transaction/getByIdCredit.json';
  private getByIdDebitJson = 'assets/mocks/transaction/getByIdDebit.json';
  private categoryPurposeJson = 'assets/mocks/transaction/categoryPurpose.json';
  private getReturnByIdJson = 'assets/mocks/transaction/getReturnById.json';
  private sendTransactionJson = 'assets/mocks/transaction/sendTransaction.json';

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return lastValueFrom(this.http.get<ITableServerResponseFilter>(this.tableFilterJson));
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableExportExcel(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }

  tableFilterSingleRecordById(
    messageId: string,
    type: string
  ): Promise<ITransactionDetail | undefined> {
    throw new Error('Method not implemented.');
  }

  getCurrentCycleTransactionsCount(): Promise<number | undefined> {
    throw new Error('Method not implemented.');
  }

  async getById(messageId: string, type: string): Promise<any> {
    const list = await this.http.get<ITableServerResponseFilter>(this.tableFilterJson).toPromise();
    const original = list?.queryable.find(x => x.messageId == messageId);

    if (original.transactionType == 'Debit')
      return lastValueFrom(this.http.get<any>(this.getByIdDebitJson));
    return lastValueFrom(this.http.get<any>(this.getByIdCreditJson));
  }

  getReturnById(messageId: string, type: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.getReturnByIdJson));
  }

  sendTransaction(transaction: ITransactionSend): Promise<IWebMessage | undefined> {
    return lastValueFrom(this.http.get<IWebMessage>(this.sendTransactionJson));
  }

  categoryPurpose(): Promise<any> {
    return lastValueFrom(this.http.get<any>(this.categoryPurposeJson));
  }
}
