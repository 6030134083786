import { ITableService } from 'src/app/common/interfaces/table/table-service.interface';
import { ITransactionSend } from '../../interfaces/transaction/send-transaction.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { ITransactionDetail } from '../../interfaces/transaction/transaction-detail.interface';

export abstract class AbstractTransactionService extends ITableService {
  abstract tableFilterSingleRecordById(
    messageId: string,
    type: string
  ): Promise<ITransactionDetail | undefined>;
  abstract getCurrentCycleTransactionsCount(): Promise<number | undefined>;
  abstract getById(messageId: string, type: string): Promise<any>;
  abstract getReturnById(messageId: string, type: string): Promise<any>;
  abstract sendTransaction(transaction: ITransactionSend): Promise<IWebMessage | undefined>;
  abstract categoryPurpose(): Promise<any>;
}
