import { Component } from '@angular/core';

@Component({
  selector: 'app-error',
  template: `
    <div style="color: red" class="error-container">
      <h1>Welcome!</h1>
      <p>
        To access our application, you will need to log in through our secure Single Sign-On the
        Proxied Redirection SSO Client.
      </p>
      <p>
        If you do not have a login or if you are having any issues, please contact our support team
        at support&#64;example.com or call us at (123) 456-7890. Our team is available from Monday
        to Friday, 9 AM to 5 PM.
      </p>
    </div>
  `,
  styles: [
    `
      .error-container {
        text-align: center;
        padding-amount: 3em;
        max-width: 500px;
        margin: auto;
      }

      .login-button {
        display: inline-block;
        margin-top: 1em;
        padding: 1em 2em;
        color: white;
        background-color: #007bff;
        border-radius: 4px;
        text-decoration: none;
      }

      .login-button:hover {
        color: white;
        background-color: #0056b3;
      }
    `,
  ],
})
export class HowtoLoginComponent {}
