import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { AbstractRFPReceiveService } from '../abstraction/abstract.rfp-receive.service';
import { IRFPList } from '../../interfaces/rfp-receive/rfp-list.interface';
import { environment } from 'src/environments/environment';
import { IRFP } from '../../interfaces/rfp-receive/rfp.interface';
import { IRFPDecision } from '../../interfaces/rfp-receive/rfp-decision.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IRFPCancellationDecision } from '../../interfaces/rfp-receive/rfp-cancellation-decision.interface';

@Injectable({
  providedIn: 'root',
})
export class RFPReceiveService extends AbstractRFPReceiveService {
  controller = `/manager/rfp-receive`;
  urlGetReturnList = `${environment.apiUrl}${this.controller}/grid`;
  urlGetReturnListExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  urlGetReturnListExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetSingleRecordReturnList = `${environment.apiUrl}${this.controller}/gridSingleRecord`;
  urlGetReturnDetailGrid = `${environment.apiUrl}${this.controller}/returnListDetail`;
  urlGetDetail = `${environment.apiUrl}${this.controller}/detail`;
  urlGetDecisionList = `${environment.apiUrl}${this.controller}/decisionlist`;
  urlGetCancellationList = `${environment.apiUrl}${this.controller}/cancellationlist`;
  urlGetStatusList = `${environment.apiUrl}${this.controller}/statuslist`;
  urlGetDenyDecisionList = `${environment.apiUrl}${this.controller}/denydecisionlist`;
  urlGetCancellationDenyList = `${environment.apiUrl}${this.controller}/cancellationdenydecisionlist`;
  urlPostDecisionAccept = `${environment.apiUrl}${this.controller}/decisionaccept`;
  urlPostDecisionDeny = `${environment.apiUrl}${this.controller}/decisiondeny`;
  urlPostCancellationDecisionAccept = `${environment.apiUrl}${this.controller}/cancellationaccept`;
  urlPostCancellationDecisionDeny = `${environment.apiUrl}${this.controller}/cancellationdeny`;
  urlPostDecisionAcceptNoteMessage = `${environment.apiUrl}${this.controller}/decisionapprovenotemessage`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetReturnList}`, filter)
      .toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetReturnListExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetReturnListExportExcel}`, filter).toPromise();
  }

  tableFilterSingleRecordById(messageId: string): Promise<IRFPList | undefined> {
    return this.http.get<IRFPList>(`${this.urlGetSingleRecordReturnList}/${messageId}`).toPromise();
  }

  getDetail(messageId: string): Promise<IRFP | undefined> {
    return this.http.get<IRFP>(`${this.urlGetDetail}/${messageId}`).toPromise();
  }

  rfpStatus(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetStatusList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  rfpCancellations(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetCancellationList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  rfpDecisionList(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetDecisionList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  rfpDenyDecision(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetDenyDecisionList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  rfpCancellationDenyDecision(): Promise<any> {
    return this.http
      .get<any>(`${this.urlGetCancellationDenyList}`)
      .toPromise()
      .then(res => res)
      .then(data => data);
  }

  rfpDecisionAccept(decision: IRFPDecision): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostDecisionAccept}`, decision).toPromise();
  }

  rfpDecisionDeny(decision: IRFPDecision): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostDecisionDeny}`, decision).toPromise();
  }

  rfpCancellationDeny(decision: IRFPCancellationDecision): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostCancellationDecisionDeny}`, decision)
      .toPromise();
  }

  rfpCancellationAccept(decision: IRFPCancellationDecision): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostCancellationDecisionAccept}`, decision)
      .toPromise();
  }

  rfpDecisionAcceptNoteMessage(decision: IRFPDecision): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostDecisionAcceptNoteMessage}`, decision)
      .toPromise();
  }
}
