import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IInstitution } from '../../interfaces/institution/institution.interface';
import { IInstitutionConfiguration } from '../../interfaces/institution/institution-configuration.interface';
import { AbstractInstitutionService } from '../abstraction/abstract.institution.service';

@Injectable({
  providedIn: 'root',
})
export class InstitutionService extends AbstractInstitutionService {
  controller = `/manager/institution`;
  urlGetInstitutionInformation = `${environment.apiUrl}${this.controller}/getinformation`;
  urlGetConfiguration = `${environment.apiUrl}${this.controller}/getconfiguration`;

  constructor(private http: HttpClient) {
    super();
  }

  getInformation(): Promise<IInstitution | undefined> {
    return this.http.get<IInstitution>(`${this.urlGetInstitutionInformation}`).toPromise();
  }

  getConfiguration(): Promise<IInstitutionConfiguration | undefined> {
    return this.http.get<IInstitutionConfiguration>(`${this.urlGetConfiguration}`).toPromise();
  }
}
