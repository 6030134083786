import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AbstractRespondentService } from '../abstraction/abstract.respondent.service';
import { IValidateParticipant } from '../../interfaces/send-payment/validate-participant.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';

@Injectable({
  providedIn: 'root',
})
export class RespondentService extends AbstractRespondentService {
  controller = `/manager/respondent`;
  urlValidateRtn = `${environment.apiUrl}${this.controller}/validateRtn`;
  urlGetReturnList = `${environment.apiUrl}${this.controller}/grid`;

  constructor(private http: HttpClient) {
    super();
  }

  validateRtn(routingNumber: string): Promise<IValidateParticipant | undefined> {
    return this.http
      .get<IValidateParticipant>(`${this.urlValidateRtn}/${routingNumber}`)
      .toPromise();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http
      .post<ITableServerResponseFilter>(`${this.urlGetReturnList}`, filter)
      .toPromise();
  }
  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }
  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    throw new Error('Method not implemented.');
  }
}
